/* float */
@keyframes float {
  0%,
  100% {
    transform: translate(0, 10px);
  }
  50% {
    transform: translate(0, -10px);
  }
}
.floating {
  animation: float 3s ease-in-out infinite;
}

/* loading dot */
@keyframes moveDot {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.loading-dot {
  animation: moveDot 1.2s ease-in-out infinite;
}

/* slide-in-from-bottom */
@keyframes slideInFromBottom {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.slide-in-from-bottom {
  animation: slideInFromBottom 0.3s ease-out 300ms forwards;
}

/* disney-menu-slide-in */
@keyframes disneyMenuSlideIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.disney-menu-slide-in {
  transform: translateY(60px);
  opacity: 0;
  animation: disneyMenuSlideIn 0.8s ease-out forwards;
}

/* back-and-forth-sway */
@keyframes sway {
  0% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(7deg);
  }
  100% {
    transform: rotate(-7deg);
  }
}
.back-and-forth-sway {
  animation: sway 2.5s infinite;
}
