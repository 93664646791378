.flip-container {
  width: 700px;
  height: 420px;
  position: relative;

  z-index: 30;
  -webkit-perspective: 1300px;
  perspective: 1300px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

.flip-page {
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;

  width: 50%;
  height: 100%;
  left: 50%;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

#first,
#first .flip-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

#first {
  z-index: 32;
}
#second {
  z-index: 33;
  transition: transform 0.8s ease-in-out;
}
#third .flip-content {
  width: 700px;
}
#fourth {
  z-index: 31;
}

.flip-page > div,
.flip-outer,
.flip-content,
.helper-class-to-make-bug-visbile {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-page > div {
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-back {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.flip-outer {
  width: 100%;
  overflow: hidden;
  z-index: 39;
}

/* problematic class: `.content` */
.flip-content {
  width: 200%;
  background: transparent;
}

.flip-front .flip-content {
  left: -100%;
}
