.blue-frame {
  background: linear-gradient(
    270deg,
    rgba(0, 187.07, 255, 0.1) 35%,
    rgba(4.7, 188.32, 255, 0.05) 62%,
    rgba(11, 190, 255, 0.03) 87%
  );
  border-radius: 8px;
  border: 1px #0bbeff dotted;
}
